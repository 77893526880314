import React, { useState } from 'react';
import './CSS/LoginSignup.css';
import { Link } from 'react-router-dom';

const LoginSignup = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [state, setState] = useState("Login");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);  // New state for checkbox

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAuth = async (url) => {
    if (!acceptedTerms) {
      setError("You must accept the terms and conditions to proceed.");
      return;
    }
    setIsLoading(true);
    setError("");
    let responseData;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });
      responseData = await response.json();
      if (response.ok && responseData.success) {
        localStorage.setItem('auth-token', responseData.token);
        window.location.replace("/");
      } else {
        throw new Error(responseData.errors || "Something went wrong");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='loginSignup'>
      <div className="loginSignup-container">
        <h1>{state}</h1>
        <div className="loginSignup-fields">
          {state === "Sign Up" && <input name="username" value={formData.username} onChange={changeHandler} type="text" placeholder='Your Name' />}
          <input name="email" value={formData.email} onChange={changeHandler} type="email" placeholder='Email Address' />
          <input name="password" value={formData.password} onChange={changeHandler} type="password" placeholder='Password' />
        </div>
        <button onClick={() => handleAuth(state === "Login" ? `${apiUrl}/login` : `${apiUrl}/signup`)}>
          {isLoading ? 'Loading...' : 'Continue'}
        </button>
        {state === "Sign Up" ? (
          <p className="loginSignup-login">Already have an account? <span onClick={() => setState("Login")}>Login Here</span></p>
        ) : (
          <p className="loginSignup-login">Create an account? <span onClick={() => setState("Sign Up")}>Register Here</span></p>
        )}
        <div className="loginSignup-agree">
          <input type="checkbox" checked={acceptedTerms} onChange={() => setAcceptedTerms(!acceptedTerms)} />
          <p>By continuing, I agree to the <Link className='' to='/policies'>terms & conditions</Link></p>
        </div>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default LoginSignup;
