import React from 'react'
import Policy from '../Components/Policy/Policy'
import WhyUs from '../Components/WhyUs/WhyUs'

const Policies = () => {
  return (
    <div>
        <Policy/>
        <WhyUs/>
    </div>
  )
}

export default Policies