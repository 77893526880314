import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import "./RoomCategory.css";
import { ShopContext } from "../../Context/ShopContext";
import Item from "../Item/Item";

const RoomCategory = () => {
  const { roomName } = useParams(); // roomName from the URL
  const { all_product, selectedProductType } = useContext(ShopContext);

  // Normalize roomName to lowercase for consistent comparison
  const normalizedRoomName = roomName.toLowerCase();
  // Filter products where the rooms array includes the normalized room name
  console.log("selectedProductType=>", selectedProductType);

  const filteredProducts = all_product.filter((item) => {
    const hasMatchingRooms =
      item.rooms &&
      item.rooms.some((room) => room.toLowerCase() === normalizedRoomName);
    const matchesCategory = item.category === selectedProductType;
    if (selectedProductType) return hasMatchingRooms && matchesCategory;
    else return hasMatchingRooms;
  });

  return (
    <div className="room-category">
      <h2>{roomName.charAt(0).toUpperCase() + roomName.slice(1)} Products</h2>
      <div className="room-products">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((item, i) => (
            <Item
              key={i}
              id={item.id}
              name={item.name}
              size={item.size}
              image={item.images[0]}
              new_price={item.new_price}
            />
          ))
        ) : (
          <p>No products available for {roomName}.</p>
        )}
      </div>
    </div>
  );
};

export default RoomCategory;
