import React from 'react'

import NewSale from '../Components/NewSale/NewSale'

const OnSale = () => {
  return (
    <div>
      
      <NewSale/>
    </div>
  )
}

export default OnSale