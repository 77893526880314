import React, { useContext } from 'react';
import { ShopContext } from '../Context/ShopContext';
import Breadcrumb from '../Components/Breadcrumbs/Breadcrumb';
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
import RelatedProducts from '../Components/RelatedProducts/RelatedProducts';

const Product = () => {
  const { all_product, selectedItemId } = useContext(ShopContext);
  const product = all_product.find((e) => e.id === Number(selectedItemId));
  if (!product) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  return (
    <div>
      <Breadcrumb product={product} />
      <ProductDisplay product={product} />
      <RelatedProducts productId={product.id} />
    </div>
  );
}

export default Product;

