import React from 'react'
import Hero from '../Components/Hero/Hero'
import Popular from '../Components/Popular/Popular'
import PopularLam from '../Components/PopularLam/PopularLam'
import WhyUs from '../Components/WhyUs/WhyUs'
import Reviews from '../Components/Reviews/Reviews'
import CategoryDisplay from '../Components/CategoryDisplay/CategoryDisplay'
import SeenIn from '../Components/SeenIn/SeenIn'


const Home = () => {
  return (
    <div>
       <Hero/>
       <Popular/>
       <PopularLam/>
       <CategoryDisplay/>
       <Reviews/>
       <WhyUs/>
       <SeenIn/>
    </div>
  )
}

export default Home
