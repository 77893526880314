// ContactUs.js
import React, { useState } from 'react';
import './ContactUs.css';
import showroom from '../Assets/showroombangor.jpg'
import { Link } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const openingTimes = [
  { day: 'Sunday', start: 13, end: 17 },
  { day: 'Monday', start: 9, end: 18 },
  { day: 'Tuesday', start: 9, end: 18 },
  { day: 'Wednesday', start: 9, end: 18 },
  { day: 'Thursday', start: 9, end: 20 },
  { day: 'Friday', start: 9, end: 18 },
  { day: 'Saturday', start: 10, end: 18 },
];

const isOpenNow = (start, end) => {
  const now = new Date();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const currentTime = hour + minute / 60;
  return currentTime >= start && currentTime < end;
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const result = await response.text();
      setStatus(result);
    } catch (error) {
      setStatus('Failed to send message');
    }
  };

  const now = new Date();
  const todayIndex = now.getDay();
  const { start, end } = openingTimes[todayIndex];
  const openStatus = isOpenNow(start, end) ? 'Open Now' : 'Currently Closed';

  return (
    <div className='contact-us'>
      <h1>Contact Us</h1>
      <div className="contact-content">
        <div className='left-content'>
          <p>If you have any questions or need assistance, please contact us using the form on the right or reach out directly.</p>
          <p>We have a large show room in Bangor Northern Ireland right beside the local fire-station; with private parking. Including a second showroom in Ballymena Northern Ireland on Queens </p>          <p>A huge collection of ceramic tiles, porcelain tiles and natural stone tiles ; as well as laminate and real wood flooring.</p>
          <p>Open 7 days a week so call down to our showrooms anytime!! Tiles Bangor & Ballymena, Laminate Bangor & Ballymena, Floor Tiles Bangor & Ballymena Northern Ireland</p>
          <div className='contact-info'>
            <p>Phone: 02891225400</p>
            <p>Email: <a href="mailto:sales@tileswoodfloorni.com">sales@tileswoodfloorni.com</a></p>
          </div>
        </div>

        <div className='right-content'>
          <form className='contact-form' onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required value={formData.name} onChange={handleChange} />

            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required value={formData.email} onChange={handleChange} />

            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" required value={formData.message} onChange={handleChange}></textarea>

            <button type="submit">Send Message</button>
          </form>
          {status && <p>{status}</p>}
        </div>
      </div>

      <div className='opening-times'>
        <div className="left-opening">
          <h2>Our Opening Times</h2>
          {openingTimes.map((time, index) => (
            <p key={index}>
              {time.day}: {time.start.toString().padStart(2, '0')}:00 - {time.end.toString().padStart(2, '0')}:00
              {index === todayIndex && <span className='status'>{openStatus}</span>}
            </p>
          ))}
          <div className="learn-more">
            <p><span>Want to know more?</span><br/> Here you can hear more from us.</p>
            <Link to="/about">
              <button className="learn-more-button">About Us</button>
            </Link>
          </div>
        </div>
        <div className='shop-image'>
        <img src={showroom} alt="Our Shop" />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

