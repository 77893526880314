import React, { useContext, useState } from "react";
import "./CartItems.css";
import { ShopContext } from "../../Context/ShopContext";
import remove_icon from "../Assets/cart_cross_icon.png";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.tileswoodfloorni.com";
const stripePromise = loadStripe(
  "pk_live_51PRuSSRpzxWSKvXb4JahVKiNuJZaiHzVTZygSAiUe9LqADWgRh7zudusydvmXWbWUjWdkkxoUFILaJbSMM0dLMu600lCIFZhUT"
);

const CartItems = () => {
  const { all_product, cartItems, removeFromCart, setSelectedItemId } =
    useContext(ShopContext);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const [address, setAddress] = useState({
    postcode: "",
    street: "",
    city: "",
    country: "Northern Ireland",
  });
  const [shippingCost, setShippingCost] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
  let totalPrice = 0;
  let totalSquareMeters = 0;
  let totalBoxes = 0;
  let totalPallets = 0;

  Object.keys(cartItems).forEach((itemId) => {
    const item = all_product.find(
      (product) => product.id.toString() === itemId
    );
    if (item) {
      const cartItem = cartItems[itemId];
      const price_per_box = item.new_price * item.box_size; // Calculate price per box
      const itemTotalPrice = price_per_box * cartItem.totalBoxes;
      totalPrice += itemTotalPrice;
      totalSquareMeters += cartItem.totalArea;
      totalBoxes += cartItem.totalBoxes;
      totalPallets += Math.ceil(cartItem.totalArea / item.pallet);
    }
  });

  const calculateShippingCost = (address, totalPallets) => {
    let costPerPallet = 0;

    switch (address.country.toUpperCase()) {
      case "NORTHERN IRELAND":
      case "IRELAND":
        costPerPallet = 85;
        break;
      case "SCOTLAND":
      case "ENGLAND":
      case "WALES":
        costPerPallet = 130;
        break;
      default:
        alert("Shipping unavailable to this location.");
        return;
    }

    return costPerPallet * totalPallets;
  };

  const handleStripeCheckout = async () => {
    if (
      deliveryMethod === "shipping" &&
      (!address.postcode ||
        !address.street ||
        !address.city ||
        !address.country)
    ) {
      setShowModal(true);
      return;
    }

    const stripe = await stripePromise;

    // Calculate shipping cost
    let calculatedShippingCost = 0;
    if (deliveryMethod === "shipping") {
      calculatedShippingCost = calculateShippingCost(address, totalPallets);
      if (calculatedShippingCost === undefined) {
        return;
      }
      setShippingCost(calculatedShippingCost);
    }

    const cartItemsArray = Object.keys(cartItems)
      .map((itemId) => {
        const item = all_product.find(
          (product) => product.id.toString() === itemId
        );
        if (item && cartItems[itemId].totalBoxes > 0) {
          const price_per_box = item.new_price * item.box_size;
          return {
            id: item.id,
            name: `${item.name} ${item.size}`,
            image: item.images[0],
            price_per_box, // Price per box
            totalBoxes: cartItems[itemId].totalBoxes,
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    if (deliveryMethod === "shipping" && calculatedShippingCost > 0) {
      cartItemsArray.push({
        id: "shipping",
        name: "Shipping Cost",
        image: "",
        price_per_box: calculatedShippingCost, // Shipping cost per box (or per unit)
        totalBoxes: 1,
      });
    }

    const authToken = localStorage.getItem("auth-token");

    const response = await fetch(`${apiUrl}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`, // Include token in the Authorization header
      },
      body: JSON.stringify({
        cartItems: cartItemsArray,
        shippingDetails: deliveryMethod === "shipping" ? address : null,
      }),
    });

    if (response.ok) {
      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } else {
      const errorData = await response.json();
      console.error("Failed to create checkout session:", errorData.error);
    }
  };
  return (
    <div className="cartItems">
      <div className="format-item">
        <div className="formatMain">
          <p>Products</p>
          <p>Title</p>
          <p>Price</p>
          <p>Quantity</p>
          <p>Total Boxes</p>
          <p>Total</p>
          <p>Remove</p>
        </div>
        <hr />
        {Object.keys(cartItems).map((itemId) => {
          const item = all_product.find(
            (product) => product.id.toString() === itemId
          );
          const cartItem = cartItems[itemId];
          if (item && cartItem.totalBoxes > 0) {
            // Check if totalBoxes is greater than 0
            return (
              <div key={itemId} className="format">
                <Link
                  to={`/product/${item.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    onClick={() => {
                      localStorage.setItem(
                        "selectedItemId",
                        JSON.stringify(item.id)
                      );
                      window.scrollTo(0, 0);
                      setSelectedItemId(item.id);
                    }}
                    src={item.images[0]}
                    alt={item.name}
                    className="cartIcon-productIcon"
                  />
                </Link>
                <p>
                  {item.name} {item.size}
                </p>
                <p>£{item.new_price}m²</p>
                <p>{cartItem.totalArea}m²</p>
                <p>{cartItem.totalBoxes}</p>
                <p>£{(item.new_price * cartItem.totalArea).toFixed(2)}</p>
                <img
                  src={remove_icon}
                  onClick={() => removeFromCart(item.id)}
                  alt="Remove Item"
                  className="cart-remove-icon"
                />
              </div>
            );
          }

          return null;
        })}
      </div>
      <div className="cartitems-down">
        <div className="cartitems-total">
          <h2>Total: £{totalPrice.toFixed(2)}</h2>
          <h2>Total m²: {totalSquareMeters.toFixed(2)}m²</h2>
          <h2>Total Boxes: {totalBoxes}</h2>
          {deliveryMethod === "shipping" && (
            <h2>Shipping Cost: £{shippingCost.toFixed(2)}</h2>
          )}
        </div>
        <div className="checkout-button-container">
          <button onClick={() => setShowDeliveryOptions(true)}>
            Continue to Checkout
          </button>
        </div>
      </div>

      {showDeliveryOptions && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-content-header">
              <h2>Choose Delivery Method</h2>
              <img
                src={remove_icon}
                alt=""
                onClick={() => {
                  setShowDeliveryOptions(false);
                }}
              />
            </div>
            <div className="modal-content-buttons">
              <button
                onClick={() => {
                  setDeliveryMethod("shipping");
                  setShowModal(true);
                  setShowDeliveryOptions(false);
                }}
              >
                Shipping
              </button>
              <button
                onClick={() => {
                  setDeliveryMethod("collection");
                  setShowModal(true);
                  setShowDeliveryOptions(false);
                }}
              >
                Collection
              </button>
              <form
                action="https://payitmonthly.uk/simple-integration/"
                method="post"
              >
                <input
                  type="hidden"
                  name="identification_key"
                  value="dryejpttghcvfnzjfzbotavy"
                />
                <input
                  type="hidden"
                  name="identification_version"
                  value="1.0.0"
                />
                <input
                  type="hidden"
                  name="goods_description"
                  value="Photos - CD - Gold Package"
                />
                <input
                  type="hidden"
                  name="goods_price"
                  value={(totalPrice * 100).toFixed(0)}
                />
                <input
                  type="hidden"
                  name="finance_deposit_total"
                  value="6000"
                />
                <input type="hidden" name="test_or_live" value="test" />
                <button type="submit">Pay It Monthly</button>
              </form>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            {deliveryMethod === "shipping" ? (
              <>
                <h2>Enter Shipping Address</h2>
                <input
                  type="text"
                  value={address.street}
                  onChange={(e) =>
                    setAddress({ ...address, street: e.target.value })
                  }
                  placeholder="Street Address"
                />
                <input
                  type="text"
                  value={address.city}
                  onChange={(e) =>
                    setAddress({ ...address, city: e.target.value })
                  }
                  placeholder="City"
                />
                <select
                  value={address.country}
                  onChange={(e) =>
                    setAddress({ ...address, country: e.target.value })
                  }
                >
                  <option value="Northern Ireland">Northern Ireland</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Scotland">Scotland</option>
                  <option value="England">England</option>
                  <option value="Wales">Wales</option>
                </select>
                <input
                  type="text"
                  value={address.postcode}
                  onChange={(e) =>
                    setAddress({ ...address, postcode: e.target.value })
                  }
                  placeholder="Postcode"
                />
                <button
                  onClick={() => {
                    if (
                      address.postcode &&
                      address.street &&
                      address.city &&
                      address.country
                    ) {
                      const calculatedShippingCost = calculateShippingCost(
                        address,
                        totalPallets
                      );
                      if (calculatedShippingCost !== undefined) {
                        setShippingCost(calculatedShippingCost);
                        setShowModal(false);
                        handleStripeCheckout();
                      }
                    }
                  }}
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    setShowModal(false);
                    setAddress({
                      postcode: "",
                      street: "",
                      city: "",
                      country: "Northern Ireland",
                    });
                  }}
                  style={{ backgroundColor: "grey" }}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <h2>Collection Address</h2>
                <p>1 Balloo Ave, Bangor, BT19 7QT</p>
                <button
                  onClick={() => {
                    setShowModal(false);
                    handleStripeCheckout();
                  }}
                >
                  Continue
                </button>
                <button
                  onClick={() => {
                    setShowModal(false);
                    setAddress({
                      postcode: "",
                      street: "",
                      city: "",
                      country: "Northern Ireland",
                    });
                  }}
                  style={{ backgroundColor: "grey" }}
                >
                  Cancel
                </button>
              </>
            )}
            <p className="excludes-text">
              Excludes Scottish Highlands and Lowlands, read full{" "}
              <Link to="/policies">policy</Link>.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartItems;
