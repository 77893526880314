import React, { useContext } from "react";
import "./Item.css";
import { Link } from "react-router-dom";
import { ShopContext } from "../../Context/ShopContext";

const Item = (props) => {
  const { setSelectedItemId } = useContext(ShopContext);

  return (
      <div className="item">
        <Link to={`/product/${props.name}`}>
          <img
            onClick={() => {
              localStorage.setItem("selectedItemId", JSON.stringify(props.id))
              window.scrollTo(0, 0);
              setSelectedItemId(props.id);
            }}
            src={props.image}
            alt=""
          />
        </Link>
        <p>
          {props.name} {props.size}
        </p>

        <div className="items-prices">
          <div className="item-price-new">£{props.new_price}m²</div>
          {props.old_price && (
            <div className="item-price-old">£{props.old_price}m²</div>
          )}
        </div>
      </div>
  );
};

export default Item;
