import React, { useEffect, useState } from "react";
import Item from "../Item/Item";
import "./NewSale.css";
import TileMan from "../Assets/tileman.png";
import useCountdown from "../Timer/Timer";

const NewSale = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [heroData, setHeroData] = useState([]);
  const [onsale, setOnSale] = useState([]);

  useEffect(() => {
    fetch(`${apiUrl}/saleitems`)
      .then((response) => response.json())
      .then((data) => {
        setOnSale(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
    fetch(`${apiUrl}/hero`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setHeroData(data))
      .catch((error) => console.error("Error fetching all products:", error));
  }, [apiUrl]);
  const countdown = useCountdown(heroData.targetDate||'');

  return (
    <div className="new-sales">
      <div className="sale-header">
        <img
          src={TileMan}
          alt="Tile Man Promoting Sale"
          className="sale-image"
        />
        <div className="sale-info">
          <h1>{heroData.heading}</h1>
          <p className="sale-tagline">Don't miss out on spectacular savings!</p>
          <div className="countdown">
            <div className="timer-item">
              <div className="timer-box2">
                {countdown.days}
                <span className="timer-label-inline">days</span>
              </div>
            </div>
            :
            <div className="timer-item">
              <div className="timer-box2">
                {countdown.hours}
                <span className="timer-label-inline">hours</span>
              </div>
            </div>
            :
            <div className="timer-item">
              <div className="timer-box2">
                {countdown.minutes}
                <span className="timer-label-inline">mins</span>
              </div>
            </div>
            :
            <div className="timer-item">
              <div className="timer-box2">
                {countdown.seconds}
                <span className="timer-label-inline">secs</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="new-item">
        {onsale.length > 0 ? (
          onsale.map((item, i) => (
            <Item
              key={item.id}
              id={item.id}
              name={item.name}
              size={item.size}
              image={item.images[0]}
              new_price={item.new_price}
              old_price={item.old_price}
            />
          ))
        ) : (
          <p>No items on sale currently.</p>
        )}
      </div>
    </div>
  );
};

export default NewSale;
