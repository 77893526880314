import React from "react";

const LVTControls = ({
  categorySettings,
  handleSortChange,
  sortCriteria,
  handleColorChange,
  handleRoomChange,
}) => {
  const lvt = categorySettings["lvt"];
  if (!lvt) {
    return <p>No LVT options available.</p>; // Fallback message
  }
  return (
    <>
      <label htmlFor="finish" className="sort-title">
        Finish:
      </label>
      <select
        name="finish"
        id="finish"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {lvt.finishOptions?.map((finish) => (
          <option key={finish} value={finish}>
            {finish}
          </option>
        ))}
      </select>

      <label htmlFor="sub_category" className="sort-title">
        Type:
      </label>
      <select
        name="sub_category"
        id="sub_category"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {lvt.typeOptions?.map((sub_category) => (
          <option key={sub_category} value={sub_category}>
            {sub_category}
          </option>
        ))}
      </select>

      <label htmlFor="thickness" className="sort-title">
        Thickness:
      </label>
      <select
        name="thickness"
        id="thickness"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {lvt.thicknessOptions?.map((thickness) => (
          <option key={thickness} value={thickness}>
            {thickness}mm
          </option>
        ))}
      </select>
      <p>Underfloor Heating</p>
      <select onChange={handleSortChange} name="underfloor_heat">
        <option value="">All</option>
        <option value="Suitable">Suitable</option>
        <option value="Not Suitable">Not Suitable</option>
      </select>
      <label htmlFor="colour" className="sort-title">
        Colours:
      </label>
      <div className="checkbox-container">
        {lvt.colourOptions?.map((color) => (
          <label key={color}>
            <input
              type="checkbox"
              name="colour"
              value={color}
              checked={sortCriteria.colour.includes(color)}
              onChange={handleColorChange}
              className="input-right"
            />
            {color}
          </label>
        ))}
      </div>
      <label htmlFor="room" className="sort-title">
        Rooms:
      </label>
      <div className="checkbox-container">
        {lvt.roomsOptions.map((room) => (
          <label key={room}>
            <input
              type="checkbox"
              name="room"
              value={room}
              checked={sortCriteria.rooms.includes(room)}
              onChange={handleRoomChange}
              className="input-right"
            />
            {room}
          </label>
        ))}
      </div>
    </>
  );
};
export default LVTControls;
