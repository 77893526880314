import React from 'react';
import './CSS/About.css';

const About = () => {
  return (
    <div className='About'>
      <h1 className='title'>About & FAQ</h1>
      <div className="about-top">
        <h1 className='green-title h1-style'>Welcome to Tiles & Wood Floor Store</h1>
        <p className='p-style'>
          Tiles & Wood Floor Store stands as a leader in providing top-tier flooring solutions across the UK, with specialized showrooms in Northern Ireland’s Bangor and Ballymena. We are dedicated to supplying both local communities and nationwide customers with superior quality tiles and wood flooring options.
        </p>
        
        <h2 className='green-title h2-style'>Explore Our Premier Flooring Collections</h2>
        <h3 className='green-title h3-style'>Tiles: A Blend of Durability and Style</h3>
        <p className='p-style'>
          Our diverse tile collection features everything from sturdy floor tiles perfect for any setting to aesthetically pleasing options in various materials like ceramic, porcelain, and natural stone. No matter your project scope—from a comprehensive home remodel to a commercial refurbishment—our tiles promise to merge quality with visual appeal.
        </p>
        
        <h3 className='green-title h3-style'>Laminate Flooring: Stylish Yet Sturdy</h3>
        <p className='p-style'>
          Recognized for its durability and elegant look, our laminate flooring mimics the warmth and aesthetics of real wood, making it an excellent choice for any space. Available in a variety of styles and suited for both residential and commercial use, our laminate options in Bangor and Ballymena ensure there’s something for every taste and budget.
        </p>
        
        <h3 className='green-title h3-style'>Wood and More: Innovative Flooring Solutions</h3>
        <p className='p-style'>
          Beyond traditional choices, we offer a selection of wood flooring and cutting-edge luxury vinyl options designed to meet the demands of modern living. Popular throughout the UK and Ireland, these products are celebrated for their long-lasting durability and low maintenance requirements.
        </p>
        
        <h2 className='green-title h2-style'>Why Choose Tiles & Wood Floor Store?</h2>
        <ul>
          <li><strong>Convenient Daily Openings:</strong> We value your convenience, which is why our doors are open seven days a week. Whether you are near our Bangor or Ballymena locations or shopping from elsewhere in the UK and Ireland, you can <a href="#types-of-tiles">visit us at your leisure</a>.</li>
          <li><strong>Dedication to Excellence:</strong> Our commitment goes beyond just offering products; we strive to deliver the best customer service possible. Our motto, "From our store to your floor," reflects our dedication to making every purchase smooth and every customer satisfied.</li>
          <li><strong>Expert Guidance:</strong> With deep knowledge of both local and broader markets, our experienced team is ready to provide advice and insights to help you choose the ideal flooring solution that meets your needs, no matter your location in the UK or Ireland.</li>
        </ul>
        
        <p>Discover why homeowners and businesses across the UK, Ireland, and beyond choose Tiles & Wood Floor Store for their flooring needs. <a href="#start-process">Visit us in-store</a> or browse our product range online to find the perfect flooring solution for your space.</p>
      </div>
      <div className="about-bottom">
        <h2 className='green-title h2-style' id="faq">FAQs About Flooring Options at Tiles & Wood Floor Store</h2>
        
        <p className='p-style'><strong id="types-of-tiles">What types of tiles are available at your stores?</strong> Tiles & Wood Floor Store offers a wide range of tiles including ceramic, porcelain, and natural stone tiles. Whether you're looking for the resilience of porcelain or the natural elegance of stone, our selection caters to every style and functional requirement.</p>

        <p className='p-style'><strong>Can laminate flooring be used in high-traffic areas?</strong> Absolutely! Laminate flooring is designed for durability and can withstand the demands of high-traffic areas. It's a popular choice for both homes and commercial spaces due to its resistance to wear and tear.</p>

        <p className='p-style'><strong>What are the advantages of choosing luxury vinyl over traditional wood flooring?</strong> Luxury vinyl flooring offers significant advantages over traditional wood, including superior moisture resistance, durability, and ease of maintenance. It's an ideal choice for areas where moisture is a concern, like kitchens and bathrooms, without sacrificing the aesthetic appeal of wood.</p>

        <p className='p-style'><strong>How do I maintain my flooring to ensure its longevity?</strong> Maintaining your flooring involves regular cleaning with the right products, immediate attention to spills, and using protective pads under furniture. For specific types of flooring, such as wood or laminate, use cleaners that are specifically designed for that material.</p>

        <p className='p-style'><strong>Are there financing options available for flooring purchases?</strong> Yes, Tiles & Wood Floor Store offers various financing options to help make your flooring purchase easier. Visit our store or contact us to explore flexible financing plans that can be tailored to your budget and needs.</p>

        <p className='p-style' id="start-process"><strong>How do I start the process of choosing the right flooring for my home?</strong> Start by visiting our showrooms or our website to explore the range of flooring options available. Consider factors such as your lifestyle, the level of traffic in your home, and your budget. Our expert team is also on hand to provide personalized advice and help you make the best choice for your space.</p>
      </div>
    </div>
  );
}

export default About;