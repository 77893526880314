import React from 'react'
import './SeenIn.css'
import IHIL from '../Assets/IHIL.png'
import channel4 from '../Assets/channel4.png'
import ulstertat from '../Assets/ulstertatler.png'
import roadsafe from '../Assets/roadsafe.png'

const SeenIn = () => {
  return (
    <div className='seen-in'>
      <h2>As Seen In</h2>
      <div className='logos-container'>
        <img src={IHIL} alt="Irelands homes interior and living" />
        <img src={channel4} alt="Channel 4" />
        <img src={ulstertat} alt="Ulster Tatler Magazine" />
        <img src={roadsafe} alt="Road Safe Northern Ireland" />
      </div>
    </div>
  )
}

export default SeenIn