import React from 'react'
import "./Locator.css"
import Hero_image from '../Assets/herodark.jpg'
import facebook_icon from '../Assets/facebook.svg'
import instagram_icon from '../Assets/instagram.svg'
import pinterest_icon from '../Assets/pinterest.svg'
import tiktok_icon from '../Assets/tiktok.svg'
import WhyUs from '../WhyUs/WhyUs'

const Locator = () => {
  return (
    <div className='stores'>
    <div className='bannerImg' style={{backgroundImage: `url(${Hero_image})`}}>
        <h1>Our Branches</h1>
    </div>
        <div className="stores-container">
          <div className="store-one">
              <div className="storeDetails">
                <h2>Bangor Showroom</h2>
                <p className="titles">
                  Address:
                </p>
                <p>1 Balloo Ave, Bangor <br />
                BT19 7QT, Northern Ireland <br />
                </p>
                <p className="titles">
                  Phone:
                </p>
                <p>028 9122 5400 <span>(Press Option 1)</span></p>
                <p className="titles">
                  Email:
                </p>
                <p>sales@tileswoodfloorni.com</p>
                <div className="store-social-icon">
                  <a href="https://www.facebook.com/tileswoodfloorni" target='_blank rel="noreferrer"'>
                      <div className="store-icon-container">
                          <img src={facebook_icon} alt="" />
                      </div>
                  </a>
                  <a href="https://www.instagram.com/tileswoodfloorni/" target='_blank rel="noreferrer"'>
                      <div className="store-icon-container">
                          <img src={instagram_icon} alt="" />
                      </div>
                  </a>
                  <a href="https://www.pinterest.co.uk/TilesWoodFloorStoreNI/" target='_blank rel="noreferrer"'>
                      <div className="store-icon-container">
                          <img src={pinterest_icon} alt="" />
                      </div>
                  </a>
                  <a href="https://www.tiktok.com/@tilesandwood" target='_blank rel="noreferrer"'>
                      <div className="store-icon-container">
                          <img src={tiktok_icon} alt="" />
                      </div>
                  </a>
                </div>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2308.758726557781!2d-5.675602400000001!3d54.6434666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4861758d2e380f63%3A0x99e3ba5d2956b0af!2sTILES%20%26%20WOOD%20FLOOR%20STORE!5e0!3m2!1sen!2suk!4v1707834549262!5m2!1sen!2suk" width="700" height="400" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Bangor'></iframe>
          </div>
          <div className="store-two">
              <div className="storeDetails">
                <h2>Ballymena Showroom</h2>
                <p className="titles">
                  Address:
                </p>
                <p>30 Queens St, Ballymena <br />
                  BT42 2BD, Northern Ireland <br />
                </p>
                <p className="titles">
                  Phone:
                </p>
                <p>028 9122 5400 <span>(Press Option 2)</span></p>
                <p className="titles">
                  Email:
                </p>
                <p>sales@tileswoodfloorni.com</p>
                <div className="store-social-icon">
                  <a href="https://www.facebook.com/tileswoodfloorstoreballymena/" target='_blank rel="noreferrer"'>
                      <div className="store-icon-container">
                          <img src={facebook_icon} alt="" />
                      </div>
                  </a>
                  <a href="https://www.instagram.com/tileswoodfloorstoreballymena/" target='_blank rel="noreferrer"'>
                      <div className="store-icon-container">
                          <img src={instagram_icon} alt="" />
                      </div>
                  </a>
                  <a href="https://www.pinterest.co.uk/TilesWoodFloorStoreNI/" target='_blank rel="noreferrer"'>
                      <div className="store-icon-container">
                          <img src={pinterest_icon} alt="" />
                      </div>
                  </a>
                  <a href="https://www.tiktok.com/@tilesandwood" target='_blank rel="noreferrer"'>
                      <div className="store-icon-container">
                          <img src={tiktok_icon} alt="" />
                      </div>
                  </a>
                </div>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2296.451224845625!2d-6.27945230583496!3d54.859887508741814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48604d6879b71f49%3A0x171eb0976d1bd348!2sTILES%20%26%20WOOD%20FLOOR%20STORE%20BALLYMENA!5e0!3m2!1sen!2suk!4v1707833917593!5m2!1sen!2suk" width="700" height="400" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Ballymena'></iframe>
          </div>
        </div>
        <WhyUs/>
    </div>
    
  )
}

export default Locator