import React from 'react'
import './WhyUs.css'
import percentage from '../Assets/0-percent.png'
import floor from '../Assets/floor.png'
import tiles from '../Assets/tiles.png'
import checkmark from '../Assets/checkmark.png'

const WhyUs = () => {
  return (
    <div className="color">
        <div className='whyUs'>
            <div className="title">
                <h3>Why Choose Us?</h3>
            </div>
            <div className="flexx">
                <div className="container">
                    <img src={percentage} alt="" />
                    <h4>0% Finance</h4>
                    <p>Explore our extensive range of tiles and flooring options, including laminate, wood, carpet, and LVT/SPC, all available with 0% finance.</p>
                </div>
                <div className="container">
                    <img src={floor} alt="" />
                    <h4>Over 300 Floors Stocked</h4>
                    <p>Find the perfect foundation from our extensive in-stock collection of over 300 laminate and wooden floors, ready to enhance any space.</p>
                </div>
                <div className="container">
                    <img src={tiles} alt="" />
                    <h4>Over 700 Tiles Stocked</h4>
                    <p>Discover our vast selection of over 700 porcelain tiles in stock, catering to all styles and preferences, ensuring the perfect match for any project.</p>
                </div>
                <div className="container">
                    <img src={checkmark} alt="" />
                    <h4>Quote Match Guarantee</h4>
                    <p>Experience our Quote Match Guarantee: find your chosen flooring or tiles at a lower price elsewhere, and we'll match or beat it.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhyUs