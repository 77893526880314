import React, { useState, useEffect } from "react";
import "./ProductDisplay.css";
import PriceCalculator from "../MeasurementForm/MeasurementForm";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.tileswoodfloorni.com";
const stripePromise = loadStripe(
  "pk_live_51PRuSSRpzxWSKvXb4JahVKiNuJZaiHzVTZygSAiUe9LqADWgRh7zudusydvmXWbWUjWdkkxoUFILaJbSMM0dLMu600lCIFZhUT"
);

const ProductDisplay = (props) => {
  const { product } = props;
  const [showModal, setShowModal] = useState(false);
  const [address, setAddress] = useState({
    postcode: "",
    street: "",
    city: "",
    country: "Northern Ireland",
  });
  const shippingCost = 9.99;
  // State to manage the main displayed image
  const [mainImage, setMainImage] = useState(product.images[0]);

  // Update mainImage whenever the product changes
  useEffect(() => {
    setMainImage(product.images[0]);
  }, [product]);

  // Wrap the single product in an array to match PriceCalculator's expected props
  const productForCalculator = [product].map((p) => ({
    ...p,
    id: p.id, // Ensure the product structure matches what PriceCalculator expects
  }));

  const handleCheckout = async () => {
    if (
      !address.postcode ||
      !address.street ||
      !address.city ||
      !address.country
    ) {
      setShowModal(true);
      return;
    }

    const stripe = await stripePromise;

    // Calculate shipping cost
    const cartItemsArray = [];
    cartItemsArray.push({
      id: product.id,
      name: product.name,
      image: product.images[0],
      price_per_box: 0, // Shipping cost per box (or per unit)
      totalBoxes: 1,
    });
    cartItemsArray.push({
      id: "shipping",
      name: "Shipping Cost",
      image: "",
      price_per_box: shippingCost, // Shipping cost per box (or per unit)
      totalBoxes: 1,
    });

    const authToken = localStorage.getItem("auth-token");

    const response = await fetch(`${apiUrl}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`, // Include token in the Authorization header
      },
      body: JSON.stringify({
        cartItems: cartItemsArray,
        shippingDetails: address,
      }),
    });

    if (response.ok) {
      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } else {
      const errorData = await response.json();
      console.error("Failed to create checkout session:", errorData.error);
    }
  };

  return (
    <div className="productDisplay">
      <div className="productDisplay-left">
        <div className="productDisplay-imgList">
          {product.images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`thumbnail-${index}`}
              onClick={() => setMainImage(img)}
              className={mainImage === img ? "active" : ""}
            />
          ))}
        </div>
        <div className="productDisplay-img">
          <img className="productDisplay-imgMain" src={mainImage} alt="main" />
          <h2
            onClick={() => {
              setShowModal(true);
            }}
          >
            Order a Free Sample
          </h2>
        </div>
      </div>
      <div className="productDisplay-right">
        <div className="productDisplay-right-left">
          <h1>
            {product.name} {product.size}
          </h1>
          <div className="priceWrapper">
            <div className="productDisplay-priceNew">
              £{product.new_price}m²
            </div>
            <div className="productDisplay-priceOld">
              <span>RRP </span>£{product.old_price}m²
            </div>
          </div>
          <hr className="hr-style" />
          <h3 className="details-header">Details:</h3>
          <div className="product-info">
            <div className="product-info-left">
              <p>
                <span> Box Size:</span> {product.box_size}m²
              </p>
              <p>
                <span>Format:</span> {product.size}cm
              </p>
              <p>
                <span>Finish:</span> {product.finish}
              </p>
              {product.category === "Laminate" ? (
                <p>
                  <span>Underfloor Heating:</span> {product.underfloor_heat}
                </p>
              ) : (
                ""
              )}
              {product.category === "real-wood" ? (
                <p>
                  <span>Underfloor Heating:</span> {product.underfloor_heat}
                </p>
              ) : (
                ""
              )}
              {product.category === "LVT" ? (
                <p>
                  <span>Underfloor Heating:</span> {product.underfloor_heat}
                </p>
              ) : (
                ""
              )}
              <p>
                <span>Available:</span>{" "}
                {product.available ? "In Stock" : "No Stock"}
              </p>
            </div>
            <div className="product-info-right">
              {product.category === "Tiles" ? (
                <>
                  <p>
                    <span>Profile:</span> {product.rectified}
                  </p>
                  <p>
                    <span>Material:</span> {product.material}
                  </p>
                </>
              ) : product.category === "Laminate" ? (
                <>
                  <p>
                    <span>AC Rating:</span> {product.ac_rating}
                  </p>
                  <p>
                    <span>Warranty:</span> {product.warranty} Years
                  </p>
                  <p>
                    <span>Water Resistant:</span>{product.water_protection}hrs
                  </p>
                </>
              ) : product.category === "real-wood" ? (
                <>
                  <p>
                    <span>Material:</span> {product.material}
                  </p>
                  <p>
                    <span>Wear Layer:</span> {product.wear_layer}
                  </p>
                </>
              ) : (
                ""
              )}
              <p>
                <span>Thickness:</span> {product.thickness}mm
              </p>
              <p>
                <span>Delivery Time:</span> {product.delivery_hour}
              </p>
            </div>
          </div>
          <h4>Description:</h4>
          <div className="productDisplay-desc">{product.desc}</div>
          <h5>Tags:</h5>
          <div className="product-tags">
            {product.category}, {product.sub_category}, {product.finish},{" "}
            {product.r_rating},
          </div>
        </div>
        <div className="productDisplay-right-right">
          <div className="priceCalculator">
            <PriceCalculator products={productForCalculator} />
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Enter Shipping Address</h2>
            <div style={{ textAlign: "start", marginBottom: "10px" }}>
              Get a free sample, just pay for shipping. The sample cost will be
              refunded when you place your final order.
            </div>
            <input
              type="text"
              value={address.street}
              onChange={(e) =>
                setAddress({ ...address, street: e.target.value })
              }
              placeholder="Street Address"
            />
            <input
              type="text"
              value={address.city}
              onChange={(e) => setAddress({ ...address, city: e.target.value })}
              placeholder="City"
            />
            <select
              value={address.country}
              onChange={(e) =>
                setAddress({ ...address, country: e.target.value })
              }
            >
              <option value="Northern Ireland">Northern Ireland</option>
              <option value="Ireland">Ireland</option>
              <option value="Scotland">Scotland</option>
              <option value="England">England</option>
              <option value="Wales">Wales</option>
            </select>
            <input
              type="text"
              value={address.postcode}
              onChange={(e) =>
                setAddress({ ...address, postcode: e.target.value })
              }
              placeholder="Postcode"
            />
            <button
              onClick={() => {
                if (
                  address.postcode &&
                  address.street &&
                  address.city &&
                  address.country
                ) {
                  setShowModal(false);
                  handleCheckout();
                }
              }}
            >
              Submit
            </button>
            <button
              onClick={() => {
                setShowModal(false);
                setAddress({
                  postcode: "",
                  street: "",
                  city: "",
                  country: "Northern Ireland",
                });
              }}
              style={{ backgroundColor: "grey" }}
            >
              Cancel
            </button>

            <p className="excludes-text">
              Excludes Scottish Highlands and Lowlands, read full{" "}
              <Link to="/policies">policy</Link>.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDisplay;
