import React from 'react';
import './NotFound.css';  // Make sure the CSS file is linked correctly
import TileMan from '../Assets/tileman.png';  // Ensure the image path is correct

const Notfound = () => {
  return (
    <div className="not-found-container">
      <img src={TileMan} alt="Lost" />
      <h1>404</h1>
      <p>Uh Oh... It looks like you got lost head back home</p>
      <button onClick={() => window.location.href = '/'}>Home</button>
    </div>
  );
}

export default Notfound;