import React from "react";

const RealWoodControls = ({
  categorySettings,
  handleSortChange,
  sortCriteria,
  handleColorChange,
}) => {
  const realWood = categorySettings["real-wood"];
  if (!realWood) {
    return <p>No real wood options available.</p>; // Fallback message
  }
  console.log("finishOptions=>", realWood.finishOptions);
  return (
    <>
      <label htmlFor="finish" className="sort-title">
        Finish:
      </label>
      <select
        name="finish"
        id="finish"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {realWood.finishOptions?.map((finish) => (
          <option key={finish} value={finish}>
            {finish}
          </option>
        ))}
      </select>

      <label htmlFor="sub_category" className="sort-title">
        Type:
      </label>
      <select
        name="sub_category"
        id="sub_category"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {realWood.typeOptions?.map((sub_category) => (
          <option key={sub_category} value={sub_category}>
            {sub_category}
          </option>
        ))}
      </select>

      <label htmlFor="thickness" className="sort-title">
        Thickness:
      </label>
      <select
        name="thickness"
        id="thickness"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {realWood.thicknessOptions?.map((thickness) => (
          <option key={thickness} value={thickness}>
            {thickness}mm
          </option>
        ))}
      </select>

      <label htmlFor="material" className="sort-title">
        Material:
      </label>
      <select
        name="material"
        id="material"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {realWood.materialOptions?.map((material) => (
          <option key={material} value={material}>
            {material}
          </option>
        ))}
      </select>

      <label htmlFor="wear_layer" className="sort-title">
        Wear Layer:
      </label>
      <select
        name="wear_layer"
        id="wear_layer"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {realWood.wear_layerOptions?.map((wearLayer) => (
          <option key={wearLayer} value={wearLayer}>
            {wearLayer}mm
          </option>
        ))}
      </select>

      <label htmlFor="brand" className="sort-title">
        Brand:
      </label>
      <select
        name="brand"
        id="brand"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {realWood.brandOptions?.map((brand) => (
          <option key={brand} value={brand}>
            {brand}
          </option>
        ))}
      </select>

      <label htmlFor="colour" className="sort-title">
        Colours:
      </label>
      <div className="checkbox-container">
        {realWood.colourOptions?.map((color) => (
          <label key={color}>
            <input
              type="checkbox"
              name="colour"
              value={color}
              checked={sortCriteria.colour.includes(color)}
              onChange={handleColorChange}
              className="input-right"
            />
            {color}
          </label>
        ))}
      </div>
    </>
  );
};
export default RealWoodControls;
