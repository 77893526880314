import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import logo from "../Assets/newlogo2.svg";
import cart_icon from "../Assets/cart-shopping-solid.svg";
import Email from "../Assets/email.svg";
import Phone from "../Assets/phone-solid-white.svg";
import location from "../Assets/location-dot-solid.svg";
import { ShopContext } from "../../Context/ShopContext";
import dropdown from "../Assets/menu_burger.svg";
import SearchBar from "../SearchBar/SearchBar";

const buttons = ["Tiles", "Laminate", "Real Wood","LVT"];

const Navbar = ({ isRoom }) => {
  const [menu, setMenu] = useState("home");
  const { getTotalCartItems, setFilterProduct } = useContext(ShopContext);
  const menuRef = useRef();
  const lowerNavRef = useRef();
  const [selected, setSelected] = useState(buttons[0]);
  const navigate = useNavigate();
  // Modified dropdown_toggle function
  const dropdown_toggle = (shouldClose) => {
    if (shouldClose) {
      menuRef.current.classList.remove("nav-menu-visible");
    } else {
      menuRef.current.classList.toggle("nav-menu-visible");
    }
  };

  const handleLinkClick = (page) => {
    setMenu(page);
    dropdown_toggle(true); // Close the menu after clicking
  };

  useEffect(() => {
    setFilterProduct(selected);
  }, [selected,setFilterProduct]);
  const handleCartClick = () => {
    if (localStorage.getItem("auth-token")) {
      navigate("/cart");
    } else {
      navigate("/login");
    }
  };

  const roomCategories = [
    "Bathroom",
    "Kitchen",
    "Hall",
    "Utility",
    "Conservatory",
    "Dining Room",
    "Living Room",
    "Study",
    "Bedroom",
    "Outdoor",
  ];

  const handleToggle = (value) => {
    setSelected(value === selected ? null : value); // Toggles selection
  };

  return (
    <div className="navbar">
      <div className="upper-nav">
        <div className="upper-left-nav">
          <a href="tel:02891225400" className="phone-link">
            <img
              style={{ verticalAlign: "middle" }}
              src={Phone}
              alt="Phone icon"
            />
            <span className="medium-bold">CALL 02891225400</span>
          </a>
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@tileswoodfloorni.com"
            className="email-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ verticalAlign: "middle" }}
              src={Email}
              alt="Email icon"
            />
            <span className="medium-bold">sales@tileswoodfloorni.com</span>
          </a>
        </div>
        <div
          onClick={() => {
            setMenu("Our Stores");
          }}
          className="upper-right-nav"
        >
          <Link className="upper-nav-bar-link" to="/our-stores">
            <img src={location} alt="Location icon" />
            <p>Our Stores</p>
          </Link>
        </div>
      </div>

      <div className="middle-nav">
        <div className="middle-nav-left">
          <div
            onClick={() => {
              setMenu("home");
            }}
            className="nav-logo"
          >
            <Link className="nav-bar-link" to="/">
              <img src={logo} alt="Tiles & Wood Floor Store Logo" />
            </Link>
          </div>
          <img
            onClick={() => dropdown_toggle(false)}
            src={dropdown}
            className="nav-dropdown"
            alt="Dropdown icon"
          />
        </div>
        <ul ref={menuRef} className="nav-menu">
          <div className="menu-items">
            <li
              onClick={() => {
                handleLinkClick("home");
              }}
            >
              <Link className="nav-bar-link" to="/">
                Home
              </Link>
              {menu === "home" ? <hr /> : null}
            </li>
            <li
              onClick={() => {
                handleLinkClick("tiles");
              }}
            >
              <Link className="nav-bar-link" to="/tiles">
                Tiles
              </Link>
              {menu === "tiles" ? <hr /> : null}
            </li>
            <li
              onClick={() => {
                handleLinkClick("Laminate");
              }}
            >
              <Link className="nav-bar-link" to="/laminate">
                Laminate
              </Link>
              {menu === "Laminate" ? <hr /> : null}
            </li>
            <li
              onClick={() => {
                handleLinkClick("Real Wood");
              }}
            >
              <Link className="nav-bar-link" to="/real-wood">
                Real Wood
              </Link>
              {menu === "Real Wood" ? <hr /> : null}
            </li>
            <li
              onClick={() => {
                handleLinkClick("LVT");
              }}
            >
              <Link className="nav-bar-link" to="/lvt">
                LVT
              </Link>
              {menu === "LVT" ? <hr /> : null}
            </li>
            <li
              onClick={() => {
                handleLinkClick("Gallery");
              }}
            >
              <Link className="nav-bar-link" to="/gallery">
                Gallery
              </Link>
              {menu === "Gallery" ? <hr /> : null}
            </li>
          </div>
          <SearchBar />
          {/* Room categories - only visible in mobile mode */}
          <div className="mobile-room-categories">
            {roomCategories.map((room, index) => (
              <li
                key={index}
                onClick={() => {
                  handleLinkClick(room);
                }}
              >
                <Link
                  className="nav-bar-link"
                  to={`/room/${room.toLowerCase()}`}
                >
                  {room}
                </Link>
                {menu === room ? <hr /> : null}
              </li>
            ))}
          </div>
        </ul>
        <div className="nav-login-cart">
          {localStorage.getItem("auth-token") ? (
            <button
              onClick={() => {
                localStorage.removeItem("auth-token");
                window.location.replace("/");
              }}
            >
              Logout
            </button>
          ) : (
            <Link style={{ textDecoration: "none" }} to="/login">
              <button>Login</button>
            </Link>
          )}
          <div onClick={handleCartClick} style={{ cursor: "pointer" }}>
            <img src={cart_icon} alt="Shopping Cart Icon" />
          </div>
          <div className="nav-cart-count">{getTotalCartItems()}</div>
        </div>
      </div>

      {/* Lower Navbar for Room Categories (visible on larger screens) */}
      <div className="lower-nav" ref={lowerNavRef}>
        <ul className="room-nav-menu">
          {roomCategories.map((room, index) => (
            <li
              key={index}
              onClick={() => {
                handleLinkClick(room);
              }}
            >
              <Link className="nav-bar-link" to={`/room/${room.toLowerCase()}`}>
                {room}
              </Link>
            </li>
          ))}
        </ul>
        {isRoom && (
          <div className="product-type-filter">
            {buttons.map((button, index) => (
              <button
                key={button}
                onClick={() => handleToggle(button)}
                className="product-type-filter-item"
                style={{
                  backgroundColor:
                    selected === button ? "white" : "rgb(99 227 87)",
                  color: selected === button ? "rgb(99 227 87)" : "#fff",
                  borderRadius:
                    index === 0
                      ? "999px 0 0 999px"
                      : index === buttons.length - 1
                      ? "0 999px 999px 0"
                      : "0",
                }}
              >
                {button}
              </button>
            ))}
          </div>
        )}
      </div>
      {isRoom && (
        <div className="product-type-filter">
          {buttons.map((button, index) => (
            <button
              key={button}
              onClick={() => handleToggle(button)}
              className="product-type-filter-item"
              style={{
                backgroundColor:
                  selected === button ? "white" : "rgb(99 227 87)",
                color: selected === button ? "rgb(99 227 87)" : "#fff",
                borderRadius:
                  index === 0
                    ? "999px 0 0 999px"
                    : index === buttons.length - 1
                    ? "0 999px 999px 0"
                    : "0",
              }}
            >
              {button}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Navbar;
