import React, { useState, useEffect } from 'react';
import './Reviews.css';

// Example reviews data
const reviewData = [
  { name: 'Colin Robb', text: 'These guys are brilliant- we needed a wooden floor put down within 10 days as we had a painter coming in. It was so much appreciated as my husband was going through Radiotherapy and was not feeling great. From first walking through the door to purchase was made so easy with helpful & friendly staff. They not only ordered the floor - and Alastair & Pawl came after work on a Saturday evening & Sunday morning to fit the floor. They certainly pride themselves on customer service. Nothing was a problem which couldn’t be sorted by Tyron & staff.', rating: 5 },
  { name: 'Patrick McBride', text: 'The guys here really know there stuff. Excellent customer service and product knowledge. Wouldn’t go anywhere else. Highly recommend Tiles & Wood floor store', rating: 5 },
  { name: 'Chris D', text: 'Great help from the guys at the tiles and wood floor store as I didn’t really no what I was looking for got guided and directed to the floor that I need thanks guys and I’ll be back again in the near future the fitting service was excellent 3 different experienced trade people really pushed on to get the job finished', rating: 5 },
  { name: 'Glen B. Nelson', text: "The staff couldn't do enough for you. Very helpful, polite and friendly. Great customer service. You'll not find a better price for tiles in North Down or County Antrim. Will certainly return and recommend. Thank you. The kitchen looks great with the tiles", rating: 5 }
];

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((current) => (current + 1) % reviewData.length);
    }, 5000); // Change review every 5000 ms (5 seconds)

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  const renderStars = (rating) => {
    return [...Array(5)].map((star, index) => (
      <span key={index} className={index < rating ? 'star filled' : 'star'}>★</span>
    ));
  };

  return (
    <div className="reviews-main">
        <h2>Customer Testimonials</h2>
        <div className='reviews'>
        <div className='review'>
            <h4>{reviewData[currentIndex].name}</h4>
            <p>{reviewData[currentIndex].text}</p>
            <div className='rating'>{renderStars(reviewData[currentIndex].rating)} <a href="https://www.google.com/search?q=tiles+%26+wood+floor+store+bangor&sca_esv=caf061f341ce1a3e&sca_upv=1&sxsrf=ACQVn08nES43-PEYLI7Xy-603-451O3rHA%3A1713706466649&ei=4hUlZqWYJ8PNhbIPiJGW2AY&gs_ssp=eJwFwUEKgCAQAEC6Br2hvdS1VdPUJ_QLRTeiaEGDotc303bTNglBH2lzVIuNH_CdrRGLtklmZZGM8vg6l1UMOkmnTcRAa3_vZ64wwsOcgE7mAvXmkiGGa-PyAy13GmI&oq=tiles+%26+wood+floor+store+b&gs_lp=Egxnd3Mtd2l6LXNlcnAiGnRpbGVzICYgd29vZCBmbG9vciBzdG9yZSBiKgIIATIKECMYgAQYJxiKBTILEC4YgAQYxwEYrwEyEBAuGIAEGBQYhwIYxwEYrwEyCxAAGIAEGIYDGIoFMgsQABiABBiGAxiKBTILEAAYgAQYhgMYigUyCBAAGIAEGKIESOoSUJ0CWJ0CcAF4AZABAJgBcKABcKoBAzAuMbgBAcgBAPgBAZgCAqACiAHCAgoQABiwAxjWBBhHmAMAiAYBkAYIkgcDMS4xoAfRDQ&sclient=gws-wiz-serp#lrd=0x4861758d2e380f63:0x99e3ba5d2956b0af,1,,,," target='_blank' rel="noreferrer"> Verified by Google</a></div>
        </div>
        </div>
    </div>
  );
};

export default Reviews;