import React, { createContext, useState, useEffect } from "react";

export const ShopContext = createContext(null);

const apiUrl = process.env.REACT_APP_API_URL;

const ShopContextProvider = (props) => {
  const [all_product, setAll_product] = useState([]);
  const [cartItems, setCartItems] = useState({});
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(() => {
    return JSON.parse(localStorage.getItem("selectedItem")) || null;
  });

  useEffect(() => {
    if(localStorage.getItem("selectedItemId"))
      setSelectedItemId(localStorage.getItem("selectedItemId"));
    
    fetch(`${apiUrl}/allproducts`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setAll_product(data))
      .catch((error) => console.error("Error fetching all products:", error));

    if (localStorage.getItem("auth-token")) {
      fetch(`${apiUrl}/getcart`, {
        method: "POST",
        headers: {
          "auth-token": `${localStorage.getItem("auth-token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => setCartItems(data))
        .catch((error) => console.error("Error fetching cart data:", error));
    }
  }, []);

  useEffect(() => {
    if (selectedItemId) {
      localStorage.setItem("selectedItemId", JSON.stringify(selectedItemId));
    }
  }, [selectedItemId]);
  const addToCart = (itemId, totalBoxes, totalArea, totalCost) => {
    setCartItems((prevCartItems) => {
      const newItem = prevCartItems[itemId]
        ? {
            ...prevCartItems[itemId],
            count: prevCartItems[itemId].count + 1,
            totalBoxes:
              prevCartItems[itemId].totalBoxes + parseFloat(totalBoxes),
            totalArea:
              parseFloat(prevCartItems[itemId].totalArea) +
              parseFloat(totalArea),
            totalCost:
              parseFloat(prevCartItems[itemId].totalCost) +
              parseFloat(totalCost),
          }
        : {
            count: 1,
            totalBoxes: parseFloat(totalBoxes),
            totalArea: parseFloat(totalArea),
            totalCost: parseFloat(totalCost),
          };

      if (localStorage.getItem("auth-token")) {
        fetch(`${apiUrl}/addtocart`, {
          method: "POST",
          headers: {
            "auth-token": `${localStorage.getItem("auth-token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ itemId, totalBoxes, totalArea, totalCost }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => console.log("Item added to cart:", data))
          .catch((error) => {
            console.error("Error adding to cart:", error);
            // Log the error message directly
            error.message && console.log(error.message);
          });
      }

      return { ...prevCartItems, [itemId]: newItem };
    });
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => {
      const existingItem = prev[itemId];
      let updatedCart = { ...prev };

      if (existingItem && existingItem.count > 1) {
        updatedCart[itemId] = {
          ...existingItem,
          count: existingItem.count - 1,
        };
      } else {
        delete updatedCart[itemId];
      }

      if (localStorage.getItem("auth-token") && existingItem) {
        fetch(`${apiUrl}/removefromcart`, {
          method: "POST",
          headers: {
            "auth-token": `${localStorage.getItem("auth-token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ itemId }),
        })
          .then((response) => response.json())
          .then((data) => console.log("Item removed from cart:", data))
          .catch((error) => console.error("Error removing from cart:", error));
      }

      return updatedCart;
    });
  };

  const getTotalCartItems = () => {
    let totalItems = 0;
    for (const key in cartItems) {
      if (cartItems.hasOwnProperty(key)) {
        totalItems += cartItems[key].count;
      }
    }
    return totalItems;
  };

  const setFilterProduct = (productType) => {
    if (productType === "Real Wood") productType = "real-wood";
    setSelectedProductType(productType);
  };

  const contextValue = {
    getTotalCartItems,
    all_product,
    cartItems,
    addToCart,
    removeFromCart,
    setFilterProduct,
    selectedProductType,
    selectedItemId,
    setSelectedItemId 
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
