import React from 'react';
import './Footer.css';
import footer_logo from '../Assets/logo.svg';
import facebook_icon from '../Assets/facebook.svg';
import instagram_icon from '../Assets/instagram.svg';
import pinterest_icon from '../Assets/pinterest.svg';
import tiktok_icon from '../Assets/tiktok.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  // Function to handle scrolling to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smooth scrolling
    });
  };

  return (
    <div className='footer'>
      <div className="footer-logo">
        <Link className='footer-logo-link' to='/' onClick={scrollToTop}>
          <img src={footer_logo} alt="Footer Logo" />
        </Link>
      </div>
      <ul className="footer-links">
        <li>Company</li>
        <Link className='footer-link-direct' to='/policies' onClick={scrollToTop}>
          <li>Policies</li>
        </Link>
        <Link className='footer-link-direct' to='/our-stores' onClick={scrollToTop}>
          <li>Stores</li>
        </Link>
        <Link className='footer-link-direct' to='/about' onClick={scrollToTop}>
        <li>About</li>
        </Link>
        <Link className='footer-link-direct' to='/contact' onClick={scrollToTop}>
        <li>Contact</li>
        </Link>
      </ul>
        <div className="footer-social-icon">
            <a href="https://www.facebook.com/tileswoodfloorni" target='_blank' rel="noreferrer">
                <div className="footer-icon-container">
                    <img src={facebook_icon} alt="" />
                </div>
            </a>
            <a href="https://www.instagram.com/tileswoodfloorni/" target='_blank' rel="noreferrer">
                <div className="footer-icon-container">
                    <img src={instagram_icon} alt="" />
                </div>
            </a>
            <a href="https://www.pinterest.co.uk/TilesWoodFloorStoreNI/" target='_blank' rel="noreferrer">
                <div className="footer-icon-container">
                    <img src={pinterest_icon} alt="" />
                </div>
            </a>
            <a href="https://www.tiktok.com/@tilesandwood" target='_blank' rel="noreferrer">
                <div className="footer-icon-container">
                    <img src={tiktok_icon} alt="" />
                </div>
            </a>
        </div>
        <div className="footer-copyright">
            <hr />
            <p>All Rights Reserved © Copyright 2024</p>
        </div>
    </div>
  )
}

export default Footer