import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./CategoryDisplay.css";
import tiles_cover from "../Assets/category_tiles.webp";
import laminate_cover from "../Assets/category_laminate.webp";
import engwood_cover from "../Assets/category_engwood.webp";
import ivt_cover from "../Assets/category_lvt.png";

const CategoryDisplay = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };
  return (
    <div className="category-display-main">
      <h1>Categories</h1>
      <div className="category-display">
        <Link to="/tiles" className="category-link" onClick={scrollToTop}>
          <div
            className="category-item"
            style={{ backgroundImage: `url(${tiles_cover})` }}
          >
            <h2>Tiles</h2>
          </div>
        </Link>
        <Link to="/laminate" className="category-link" onClick={scrollToTop}>
          <div
            className="category-item"
            style={{ backgroundImage: `url(${laminate_cover})` }}
          >
            <h2>Laminate</h2>
          </div>
        </Link>
        <Link to="/real-wood" className="category-link" onClick={scrollToTop}>
          <div
            className="category-item"
            style={{ backgroundImage: `url(${engwood_cover})` }}
          >
            <h2>Engineered Wood</h2>
          </div>
        </Link>
        <Link to="/lvt" className="category-link" onClick={scrollToTop}>
          <div
            className="category-item"
            style={{ backgroundImage: `url(${ivt_cover})` }}
          >
            <h2>LVT</h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CategoryDisplay;
