import React from 'react'
import ContactUs from '../Components/ContactUs/ContactUs'

const Contact = () => {
  return (
    <div>
        <ContactUs/>
    </div>
  )
}

export default Contact