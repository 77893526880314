import React, { useState, useEffect } from 'react';
import './RelatedProducts.css';
import Item from '../Item/Item';

const RelatedProducts = ({ productId }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const apiUrl = "https://api.tileswoodfloorni.com";

  useEffect(() => {
    if (productId) {
      fetch(`${apiUrl}/relatedproducts/${productId}`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success && data.products) {
            setRelatedProducts(data.products);
          } else {
            console.error('Failed to fetch related products:', data.message);
            setRelatedProducts([]);
          }
        })
        .catch(error => {
          console.error('Error fetching related products:', error);
          setRelatedProducts([]);
        });
    }
  }, [productId]);

  return (
    <div className='RelatedProducts'>
      <h1>Related Products</h1>
      <hr />
      <div className="RelatedProducts-item">
        {relatedProducts.length > 0 ? (
          relatedProducts.map((item, i) => {
            return <Item key={i} id={item.id} name={item.name} size={item.size}  image={item.images[0]} new_price={item.new_price} old_price={item.old_price} />
          })
        ) : (
          <p>No related products found.</p>
        )}
      </div>
    </div>
  );
}

export default RelatedProducts;

