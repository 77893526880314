import React from 'react'
import Locator from '../Components/Stores/Locator'


const OurStores = () => {
  return (
    <div className='stores'>
      <Locator/>
    </div>
  )
}

export default OurStores