import React from "react";

const TilesControls = ({
  categorySettings,
  handleSortChange,
  sortCriteria,
  handleColorChange,
  handleRoomChange,
}) => {
  return (
    <>
      <label htmlFor="size" className="sort-title">
        Size:
      </label>
      <select
        name="size"
        id="size"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {categorySettings.tiles.sizeOptions.map((size) => (
          <option key={size} value={size}>
            {size}cm
          </option>
        ))}
      </select>

      <label htmlFor="finish" className="sort-title">
        Finish:
      </label>
      <select
        name="finish"
        id="finish"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {categorySettings.tiles.finishOptions.map((finish) => (
          <option key={finish} value={finish}>
            {finish}
          </option>
        ))}
      </select>

      <label htmlFor="sub_category" className="sort-title">
        Type:
      </label>
      <select
        name="sub_category"
        id="sub_category"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {categorySettings.tiles.typeOptions.map((sub_category) => (
          <option key={sub_category} value={sub_category}>
            {sub_category}
          </option>
        ))}
      </select>

      <label htmlFor="thickness" className="sort-title">
        Thickness:
      </label>
      <select
        name="thickness"
        id="thickness"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {categorySettings.tiles.thicknessOptions.map((thickness) => (
          <option key={thickness} value={thickness}>
            {thickness}mm
          </option>
        ))}
      </select>

      <label htmlFor="material" className="sort-title">
        Material:
      </label>
      <select
        name="material"
        id="material"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {categorySettings.tiles.materialOptions.map((material) => (
          <option key={material} value={material}>
            {material}
          </option>
        ))}
      </select>

      <label htmlFor="brand" className="sort-title">
        Brand:
      </label>
      <select
        name="brand"
        id="brand"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {categorySettings.tiles.brandOptions.map((brand) => (
          <option key={brand} value={brand}>
            {brand}
          </option>
        ))}
      </select>

      <label htmlFor="rectified" className="sort-title">
        Rectified:
      </label>
      <select
        name="rectified"
        id="rectified"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {categorySettings.tiles.rectifiedOptions.map((rectified) => (
          <option key={rectified} value={rectified}>
            {rectified}
          </option>
        ))}
      </select>

      <label htmlFor="colour" className="sort-title">
        Colours:
      </label>
      <div className="checkbox-container">
        {categorySettings.tiles.colourOptions.map((color) => (
          <label key={color}>
            <input
              type="checkbox"
              name="colour"
              value={color}
              checked={sortCriteria.colour.includes(color)}
              onChange={handleColorChange}
              className="input-right"
            />
            {color}
          </label>
        ))}
      </div>
      <label htmlFor="room" className="sort-title">
        Rooms:
      </label>
      <div className="checkbox-container">
        {categorySettings.tiles.roomsOptions.map((room) => (
          <label key={room}>
            <input
              type="checkbox"
              name="room"
              value={room}
              checked={sortCriteria.rooms.includes(room)}
              onChange={handleRoomChange}
              className="input-right"
            />
            {room}
          </label>
        ))}
      </div>
    </>
  );
};
export default TilesControls;
