import React from 'react'
import Gallery from '../Components/Gallery/Gallery'

const Photos = () => {
  return (
    <div className='photos'>
        <Gallery/>
    </div>
  )
}

export default Photos