import React, { useState, useEffect } from 'react';
import './Popular.css';
import Item from '../Item/Item';

const apiUrl = process.env.REACT_APP_API_URL;

const Popular = () => {
  const [popularProducts, setPopularProducts] = useState([]);

  useEffect(() => {
    fetch(`${apiUrl}/popularproducts`)
      .then((response) => response.json())
      .then((data) => setPopularProducts(data))
      .catch((error) => console.error('Error fetching popular products:', error));
  }, []);

  return (
    <div className='popular'>
      <h1>Popular Tiles</h1>
      <div className="popular-item">
        {popularProducts.map((item, i) => (
          <Item
            key={i}
            id={item.id}
            name={item.name}
            size={item.size}
            image={item.images[0]}
            new_price={item.new_price}
            old_price={item.old_price}
          />
        ))}
      </div>
    </div>
  );
};

export default Popular;

