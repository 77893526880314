import React, { useState } from "react";
import "./Policy.css";
import drop_arrow from "../Assets/circle-chevron-down-solid.svg";

// Policy sections data
const policies = [
  {
    title: "Terms of Service",
    content: `Ensure that the new terms are compliant with current business practices, including customer responsibilities, privacy policies, delivery terms, and governing law.`,
  },
  {
    title: "Return Policy",
    content: `Items sourced from Spain are custom-made specifically for your order and, as such, cannot be refunded. These are non-stock items and are exempt from refunds in accordance with trading standards. Due to the unique printing process, tiles may feature slight variations in shading and texture, further classifying your order as bespoke. We appreciate your understanding and advise careful consideration before placing these orders, as once the order has been confirmed, it cannot be altered or cancelled.
Stock items, however, may be refunded according to our company policies, provided they meet the following conditions:
    <li>Items must be in original, unopened packaging, undamaged, and in resellable condition.</li>
    <li>Returns must be initiated within 14 days of delivery.</li>
    <li>A 25% restocking fee will be applied to all eligible returns.</li>
    <li>Certain exclusions apply (e.g., clearance or sale items).</li>
    <li>Delivery fees and installation services are non-refundable.</li>
    <li>Contact customer service to initiate returns and obtain an RMA number.</li>`,
  },
  {
    title: "Damages / Incorrect Delivery",
    content: `In the event that you receive damaged or incorrect products, kindly notify Tiles & Wood Floor Store within 2 working days from the date of delivery or collection. We will make every effort to promptly replace the affected items.`,
  },
  {
    title: "Unused Products",
    content: `Within 14 days from the date of delivery, we will issue a refund for any unopened, undamaged boxes of stock products, provided they are returned in their original condition and packaging. However, certain perishable items such as adhesives, grouts, and sealants cannot be returned.
Additionally, bespoke items, custom orders, and items sourced from overseas (including products from Spain) are non-returnable and non-refundable, as they are made to order.`,
  },
  {
    title: "Policy",
    content: `We offer complimentary storage for your products for as long as needed. However, we kindly request that any remaining balance be settled upon arrival. Please note that collections cannot be arranged on Sundays due to limited staffing. To avoid any scheduling issues, we recommend refraining from booking a fitter until your products have been delivered.
For products sourced from Spain, please expect delivery within 1-2 weeks. Your confirmation email will provide specific shipping times, and each product listing includes delivery estimates as well. Please note that online orders are subject to these delivery timelines.
We use a haulage network to transport your items. While we maintain close collaboration with them, they operate independently from our company. As such, we depend on their estimated delivery timelines. In the rare event of any delays, we ask for your patience.`,
  },
];

const Policy = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (index) => {
    setOpenSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="policies">
      {policies.map((policy, index) => (
        <div key={index} className="policy-section">
          <button className="policy-title" onClick={() => toggleSection(index)}>
            {policy.title}
            <img
              src={drop_arrow}
              className={`arrow-icon ${openSections[index] ? "open" : ""}`}
              alt="Toggle visibility"
            />
          </button>
          {openSections[index] && (
            <div dangerouslySetInnerHTML={{ __html: policy.content }} />
          )}
        </div>
      ))}
    </div>
  );
};

export default Policy;
