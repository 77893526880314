import React, { useContext, useState } from "react";
import "./CSS/ShopCategory.css";
import { ShopContext } from "../Context/ShopContext";
import Item from "../Components/Item/Item";
import FilterOptions from "../Components/ShopCategory/FilterOptions";

const categorySettings = {
  tiles: {
    sizeOptions: [
      "10x10",
      "6.5x3",
      "7.5x15",
      "7.5x30",
      "10x20",
      "10x30",
      "15x15",
      "15x20",
      "20x20",
      "25x40",
      "25x50",
      "30x30",
      "30x60",
      "30x90",
      "40x120",
      "20x20",
      "30x30",
      "33x33",
      "45x45",
      "50x50",
      "30x60",
      "40x40",
      "45x90",
      "60x60",
      "75x75",
      "60x120",
      "80x80",
      "90x90",
      "10x60",
      "20x60",
      "15x60",
      "20x120",
      "30x150",
      "20x90",
      "15x90",
      "30x120",
      "30x180",
      "20x180",
      "23x120",
      "7x8",
      "15x17",
      "20x24",
      "25x29",
      "80x180",
      "100x300",
      "120x60",
      "120x240",
      "120x280",
      "180x280",
    ],
    finishOptions: ["Matt", "Glossy", "Lappato"],
    typeOptions: ["Floor & Wall", "Wall", "Outdoor", "Pattern", "Wood Effect"],
    thicknessOptions: ["8", "9", "10", "10.5"],
    materialOptions: ["Porcelain", "Red Ceramic", "White Ceramic"],
    brandOptions: [
      "Baldocer",
      "Cristacer",
      "KTL",
      "Red",
      "STN",
      "Style",
      "Tau",
      "Vista Original",
    ],
    rectifiedOptions: ["Rectified", "Non-Rectified"],
    colourOptions: [
      "White",
      "Beige",
      "Grey",
      "Cream",
      "Brown",
      "Black",
      "Blue",
      "Green",
      "Red",
      "Yellow",
      "Pink",
    ],
    roomsOptions: [
      "Bathroom",
      "Kitchen",
      "Hall",
      "Utility",
      "Conservatory",
      "Dining Room",
      "Living Room",
      "Study",
      "Bedroom",
      "Outdoor",
    ],
  },
  laminate: {
    sizeOptions: ["20x140", "20x130", "14x64", "10x60", "24x202", "16x140"],
    finishOptions: ["Matt", "Glossy"],
    typeOptions: ["Straight Plank", "Herringbone", "XXL Plank", "Thin Plank"],
    thicknessOptions: ["7", "8", "9", "10", "12"],
    materialOptions: ["AC3", "AC4", "AC5", "AC6"],
    brandOptions: [
      "Krono Original",
      "Kronopol",
      "Shamrock",
      "Egger",
      "Supreme",
      "Balterio",
      "Floor2go",
      "Alsa",
      "Classen",
      "Vista Original",
    ],
    warrantyOptions: ["15", "20", "25", "30", "35"],
    colourOptions: [
      "Dark",
      "Light",
      "Medium",
      "Grey",
      "White",
      "Brown",
      "Multi-Coloured",
    ],
    roomsOptions: [
      "Bathroom",
      "Kitchen",
      "Hall",
      "Utility",
      "Conservatory",
      "Dining Room",
      "Living Room",
      "Study",
      "Bedroom",
      "Outdoor",
    ],
  },
  "real-wood": {
    finishOptions: ["Matt", "Oiled", "Lacquered", "Stained"],
    typeOptions: [
      "Real Wood",
      "Engineered",
      "Real Wood Herringbone",
      "Engineered Herringbone",
    ],
    thicknessOptions: ["14", "15", "16", "17", "18", "19", "20"],
    materialOptions: [
      "Oak",
      "Maple",
      "Cherry",
      "Walnut",
      "Mahogany",
      "Birch",
      "Hickory",
      "Teak",
      "Pine",
      "Cedar",
      "Douglas Fir",
      "Spruce",
    ],
    wear_layerOptions: ["0.6", "1.2", "2", "3", "4", "5", "6"],
    brandOptions: [
      "Vista Original",
      "Vista Herringbone",
      "Intasa Plank",
      "Intasa Herringbone",
      "Esco Plank",
      "Esco Herringbone",
      "Esco Wide Plank",
      "Zenon Plank",
      "Zenon Herringbone",
      "The Twelve",
      "Hakwood",
      "Hakwood Herringbone",
      "Designed by Nature",
      "Designed by Nature Herringbone",
    ],
    colourOptions: [
      "Dark",
      "Light",
      "Medium",
      "Grey",
      "White",
      "Brown",
      "Multi-Coloured",
    ],
  },
  lvt: {
    finishOptions: ["Matt", "Glossy", "Brushed", "Textured", "Embossed"],
    typeOptions: [
      "Click LVT",
      "Click SPC",
      "Glue-down LVT",
      "Loose-lay LVT",
      "Self-adhesive LVT",
    ],
    thicknessOptions: [
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
      "5.5",
      "6",
      "6.5",
      "7",
      "7.5",
      "8",
      "8.5",
      "9",
      "9.5",
      "10",
      "10.5",
    ],
    colourOptions: [
      "White",
      "Dark",
      "Light",
      "Medium",
      "Grey",
      "Brown",
      "Stone Beige",
      "Stone Grey",
      "Charcoal Stone",
    ],
    roomsOptions: [
      "Bathroom",
      "Kitchen",
      "Hall",
      "Utility",
      "Conservatory",
      "Dining Room",
      "Living Room",
      "Study",
      "Bedroom",
      "Outdoor",
    ],
  },
};

const ShopCategory = (props) => {
  const { category } = props;
  const normalizedCategory = category.toLowerCase();
  const { all_product } = useContext(ShopContext);
  console.log("category=>", category);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortCriteria, setSortCriteria] = useState({
    size: "",
    finish: "",
    sub_category: "",
    thickness: "",
    material: "",
    brand: "",
    rectified: "",
    ac_rating: "",
    underfloor_heat: "",
    water_protection: "",
    warranty: "",
    additional: {},
    colour: [],
    rooms: [],
    minPrice: 0,
    maxPrice: 130,
  });

  const [showSorting, setShowSorting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const matchesSearchTerm = (item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.name.toLowerCase().includes(searchLower) ||
      item.size.toLowerCase().includes(searchLower) ||
      item.finish.toLowerCase().includes(searchLower) ||
      item.sub_category?.toLowerCase().includes(searchLower)
    );
  };

  const handleColorChange = (e) => {
    const { value } = e.target;
    setSortCriteria((prev) => {
      const newColours = prev.colour.includes(value)
        ? prev.colour.filter((c) => c !== value)
        : [...prev.colour, value];
      return { ...prev, colour: newColours };
    });
  };

  const handleRoomChange = (e) => {
    const { value } = e.target;
    setSortCriteria((prev) => {
      const newRooms = prev.rooms.includes(value)
        ? prev.rooms.filter((c) => c !== value)
        : [...prev.rooms, value];
      return { ...prev, rooms: newRooms };
    });
  };

  const handleSortChange = (e) => {
    const { name, value } = e.target;
    setSortCriteria((prev) => ({ ...prev, [name]: value }));
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setSortCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filteredProducts = all_product.filter((item) => {
    const itemCategoryNormalized = item.category?.toLowerCase() || "";

    const matchesThickness = sortCriteria.thickness
      ? item.thickness && item.thickness.toString() === sortCriteria.thickness
      : true;
    const matchesSubCategory = sortCriteria.sub_category
      ? (item.sub_category || "Unknown").toLowerCase() ===
        sortCriteria.sub_category.toLowerCase()
      : true;
    const matchesPriceRange =
      item.new_price >= sortCriteria.minPrice &&
      item.new_price <= sortCriteria.maxPrice;

    return (
      itemCategoryNormalized === normalizedCategory &&
      (!sortCriteria.colour.length ||
        sortCriteria.colour.some(
          (color) => item.colour && item.colour.includes(color)
        )) &&
      (!sortCriteria.rooms.length ||
        sortCriteria.rooms.some(
          (room) => item.rooms && item.rooms.includes(room)
        )) &&
      (sortCriteria.size ? item.size === sortCriteria.size : true) &&
      (sortCriteria.finish ? item.finish === sortCriteria.finish : true) &&
      matchesSubCategory &&
      matchesThickness &&
      (sortCriteria.material
        ? item.material === sortCriteria.material
        : true) &&
      (sortCriteria.brand ? item.brand === sortCriteria.brand : true) &&
      (sortCriteria.rectified
        ? item.rectified === sortCriteria.rectified
        : true) &&
      (sortCriteria.ac_rating
        ? item.ac_rating === sortCriteria.ac_rating
        : true) &&
      (sortCriteria.warranty
        ? item.warranty === sortCriteria.warranty
        : true) &&
      (sortCriteria.water_protection
        ? item.water_protection === sortCriteria.water_protection
        : true) &&
      (sortCriteria.underfloor_heat
        ? item.underfloor_heat === sortCriteria.underfloor_heat
        : true) &&
      matchesSearchTerm(item) &&
      matchesPriceRange
    );
  });

  const displayedProducts = filteredProducts.slice(
    0,
    currentPage * itemsPerPage
  );

  return (
    <div className="shop-category">
      <div className="sidebar">
        <div className="search-container">
          <input
            type="text"
            placeholder={`Search ${props.category || "all categories"}...`}
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          <button onClick={handleClearSearch} className="clear-button">
            Clear
          </button>
        </div>

        <div className="price-inputs">
          <div className="price-input-group">
            <label htmlFor="minPrice">Min Price:</label>
            <input
              type="number"
              name="minPrice"
              min="0"
              max="130"
              value={sortCriteria.minPrice}
              onChange={handlePriceChange}
              className="price-input1"
            />
          </div>
          <div className="price-input-group">
            <label htmlFor="maxPrice">Max Price:</label>
            <input
              type="number"
              name="maxPrice"
              min="0"
              max="130"
              value={sortCriteria.maxPrice}
              onChange={handlePriceChange}
              className="price-input2"
            />
          </div>
        </div>

        <button
          className="toggle-button"
          onClick={() => setShowSorting(!showSorting)}
        >
          {showSorting ? "Hide Filters" : "Show Filters"}
        </button>
        <div className={`sorting-controls ${showSorting ? "show" : ""}`}>
          <FilterOptions
            categorySettings={categorySettings}
            handleSortChange={handleSortChange}
            normalizedCategory={normalizedCategory}
            sortCriteria={sortCriteria}
            handleColorChange={handleColorChange}
            handleRoomChange={handleRoomChange}
          />
        </div>
      </div>
      <div className="product-area">
        <div className="product-count">
          <p>
            <span>
              Showing {displayedProducts.length} out of{" "}
              {filteredProducts.length} products
            </span>
          </p>
        </div>
        <div className="shopcategory-products">
          {displayedProducts.map((item, i) => (
            <Item
              key={i}
              id={item.id}
              name={item.name}
              size={item.size}
              image={item.images[0]}
              new_price={item.new_price}
            />
          ))}
        </div>
        {displayedProducts.length < filteredProducts.length && (
          <button
            className="load-more-button"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Load More
          </button>
        )}
      </div>
    </div>
  );
};

export default ShopCategory;
