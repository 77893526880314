import React from 'react';
import './Breadcrumb.css';
import arrow_icon from '../Assets/breadcrum_arrow.png';
import { Link } from 'react-router-dom';

const Breadcrum = (props) => {
    const { product } = props;
    
    // Function to create a URL-friendly string from the category name
    const formatCategoryToPath = (categoryName) => {
        return `/${categoryName.toLowerCase().replace(/\s+/g, '-')}`;
    };

    return (
        <div className='breadcrumb'>
            <Link className='breadcrumb-link' to='/'>Home</Link>
            <img src={arrow_icon} alt="Arrow icon" />
            <Link className='breadcrumb-link' to={formatCategoryToPath(product.category)}>
                {product.category}
            </Link>
            <img src={arrow_icon} alt="Arrow icon" />
            <span>{product.name}</span>
        </div>
    );
}

export default Breadcrum;