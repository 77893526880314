import React from "react";
import RealWoodControls from "./RealWoodControls";
import LaminateControls from "./LaminateControls";
import TilesControls from "./TilesControls";
import LVTControls from "./LVTControls";

const FilterOptions = (props) => {
  if (props.normalizedCategory === "tiles") {
    return <TilesControls {...props} />;
  } else if (props.normalizedCategory === "laminate") {
    return <LaminateControls {...props} />;
  } else if (props.normalizedCategory === "real-wood") {
    return <RealWoodControls {...props} />;
  } else if (props.normalizedCategory === "lvt") {
    return <LVTControls {...props} />;
  }
};

export default FilterOptions;
