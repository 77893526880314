import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ShopCategory from "./Pages/ShopCategory";
import Home from "./Pages/Home";
import Product from "./Pages/Product";
import Cart from "./Pages/Cart";
import LoginSignup from "./Pages/LoginSignup";
import OnSale from "./Pages/OnSale";
import OurStores from "./Pages/OurStores";
import Footer from "./Components/Footer/Footer";
import Policies from "./Pages/Policies";
import Photos from "./Pages/Photos";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Success from "./Components/Success/Success";
import NotFound from "./Components/NotFound/NotFound";
import RoomCategory from "./Components/RoomCategory/RoomCategory";
import Layout from "./Layout";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/tiles"
            element={
              <Layout>
                <ShopCategory category="Tiles" />
              </Layout>
            }
          />
          <Route
            path="/laminate"
            element={
              <Layout>
                <ShopCategory category="Laminate" />
              </Layout>
            }
          />
          <Route
            path="/real-wood"
            element={
              <Layout>
                <ShopCategory category="real-wood" />
              </Layout>
            }
          />
          <Route
            path="/lvt"
            element={
              <Layout>
                <ShopCategory category="lvt" />
              </Layout>
            }
          />
          <Route
            path="/gallery"
            element={
              <Layout>
                <Photos />
              </Layout>
            }
          />
          <Route
            path="/room/:roomName"
            element={
              <Layout isRoom={true}>
                <RoomCategory />
              </Layout>
            }
          />
          <Route
            path="product"
            element={
              <Layout>
                <Product />
              </Layout>
            }
          >
            <Route
              path=":productId"
              element={
                <Layout>
                  <Product />
                </Layout>
              }
            />
            <Route
              path="*"
              element={
                <Layout>
                  <Product />
                </Layout>
              }
            />
          </Route>
          <Route
            path="/cart"
            element={
              <Layout>
                <Cart />
              </Layout>
            }
          />
          <Route
            path="/login"
            element={
              <Layout>
                <LoginSignup />
              </Layout>
            }
          />
          <Route
            path="/sale"
            element={
              <Layout>
                <OnSale />
              </Layout>
            }
          />
          <Route
            path="/our-stores"
            element={
              <Layout>
                <OurStores />
              </Layout>
            }
          />
          <Route
            path="/policies"
            element={
              <Layout>
                <Policies />
              </Layout>
            }
          />
          <Route
            path="/contact"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="/success/:orderId"
            element={
              <Layout>
                <Success />
              </Layout>
            }
          />
          <Route
            path="*"
            element={
              <Layout>
                <NotFound />
              </Layout>
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
