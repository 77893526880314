import Navbar from "../Components/Navbar/Navbar";
import { useEffect } from "react";
const Layout = ({ children, isRoom = false }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://consent.cookiebot.com/uc.js";
    script.setAttribute("data-cbid", "09f6e310-0bcb-4e17-be19-b2465979e5ac");
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });
  return (
    <>
      <Navbar isRoom={isRoom} />
      {children}
    </>
  );
};

export default Layout;
