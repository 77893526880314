import React from "react";

const LaminateControls = ({
  categorySettings,
  handleSortChange,
  sortCriteria,
  handleColorChange,
  handleRoomChange,
}) => {
  const laminate = categorySettings.laminate;
  return (
    <>
      <label htmlFor="size" className="sort-title">
        Size:
      </label>
      <select
        name="size"
        id="size"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {laminate.sizeOptions?.map((size) => (
          <option key={size} value={size}>
            {size}cm
          </option>
        ))}
      </select>

      <label htmlFor="finish" className="sort-title">
        Finish:
      </label>
      <select
        name="finish"
        id="finish"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {laminate.finishOptions?.map((finish) => (
          <option key={finish} value={finish}>
            {finish}
          </option>
        ))}
      </select>

      <label htmlFor="sub_category" className="sort-title">
        Type:
      </label>
      <select
        name="sub_category"
        id="sub_category"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {laminate.typeOptions?.map((sub_category) => (
          <option key={sub_category} value={sub_category}>
            {sub_category}
          </option>
        ))}
      </select>

      <label htmlFor="thickness" className="sort-title">
        Thickness:
      </label>
      <select
        name="thickness"
        id="thickness"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {laminate.thicknessOptions?.map((thickness) => (
          <option key={thickness} value={thickness}>
            {thickness}mm
          </option>
        ))}
      </select>

      <label htmlFor="material" className="sort-title">
        Material:
      </label>
      <select
        name="material"
        id="material"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {laminate.materialOptions?.map((material) => (
          <option key={material} value={material}>
            {material}
          </option>
        ))}
      </select>

      <label htmlFor="brand" className="sort-title">
        Brand:
      </label>
      <select
        name="brand"
        id="brand"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {laminate.brandOptions?.map((brand) => (
          <option key={brand} value={brand}>
            {brand}
          </option>
        ))}
      </select>

      <label htmlFor="warranty" className="sort-title">
        Warranty:
      </label>
      <select
        name="warranty"
        id="warranty"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        {laminate.warrantyOptions?.map((warranty) => (
          <option key={warranty} value={warranty}>
            {warranty} Years
          </option>
        ))}
      </select>

      <label htmlFor="water_protection" className="sort-title">
        Water Protection:
      </label>
      <select
        name="water_protection"
        id="water_protection"
        onChange={handleSortChange}
        className="shop-select"
      >
        <option value="">All</option>
        <option value="24">Water Resistant(24hr)</option>
        <option value="48">Water Resistant(48hr)</option>
        <option value="72">Water Resistant(72hr)</option>
        <option value="Basic Protection">Basic Water Resistant</option>
      </select>

      <label htmlFor="colour" className="sort-title">
        Colours:
      </label>
      <div className="checkbox-container">
        {laminate.colourOptions?.map((color) => (
          <label key={color}>
            <input
              type="checkbox"
              name="colour"
              value={color}
              checked={sortCriteria.colour.includes(color)}
              onChange={handleColorChange}
              className="input-right"
            />
            {color}
          </label>
        ))}
      </div>
      <label htmlFor="room" className="sort-title">
        Rooms:
      </label>
      <div className="checkbox-container">
        {laminate.roomsOptions.map((room) => (
          <label key={room}>
            <input
              type="checkbox"
              name="room"
              value={room}
              checked={sortCriteria.rooms.includes(room)}
              onChange={handleRoomChange}
              className="input-right"
            />
            {room}
          </label>
        ))}
      </div>
    </>
  );
};
export default LaminateControls;
